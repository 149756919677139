.widget-containerNotAuth {
    position: relative;
    height: 50px;
    width: 97%;
    overflow: hidden;
    margin: 10px 10px;
}
.widget-preloader {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 246px;
    background-color: #000000;
    
    & img {
        width: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .widget-containerNotAuth {
        width: 95%;
    }
}
