 @mixin banner-hw() {
  width: 355px;
  height: 177px;

  @media (max-width: 475px) {
    width: 100%;
    height: 48vw;
  }
}

 .slider_banner__item,
 .banner-one__item {
  width: 310px;
  height: 150px;
  padding: 0 10px;
  &_img {
    height: 150px;
    border-radius: 10px;
    width: 300px;

    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;

    &:hover {
      cursor: pointer;
      }
  }
}

 @media (max-width: 460px) {
   .slider_banner__item,
   .banner-one__item {
     width: 100vw !important;
     display: flex !important;
     align-items: center;
     justify-content: center;
     height: auto;
     &_img {
       //height: 150px;
       height: 200px;
       //width: 300px;
       width: calc(100vw - 30px);
       background-size: cover;
     }
   }
 }

 @media (max-width: 420px) {
   .slider_banner__item,
   .banner-one__item {
     &_img {
       height: 140px;
     }
   }
 }
 @media (max-width: 375px) {
   .slider_banner__item,
   .banner-one__item {
     &_img {
       height: 140px;
     }
   }
 }

.banner-one__item {
  width: 100%;
  padding: 0 10px;
}
.banner-one__img {
  @include banner-hw();
  border-radius: 10px;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;

  & :hover {
    cursor: pointer;
    }
}
