@import '../../styles.scss';

.left-side-menu {

  --width: var(--menu-width);
}

.left-side-menu-item {
  @include menu-item();

  &__icon {
    font-family: 'apollo';
    height: 25px !important;
    width: 25px !important;

    .svg {
      margin-top: 2px;
    }
  }
}

.left-side-menu-version{
  width: 100%;
  text-align: center;
}

.socials {
  height: 50px;
  width: 90%;
  position: relative;
  margin: 0px 20px 400px;

  .left-side-menu-item__label_Whatsapp {
    line-height: 50px !important;
  }

  .instagram-button{
    height: 50px;
    width: 100%;
    background: url("../../assets/images/socials/instagram.svg");
    background-repeat: no-repeat;
  }

  .telegram-button{
    height: 50px;
    width: 100%;
    background: url("../../assets/images/socials/telegram.svg");
    background-repeat: no-repeat;
  }

  .telegram-support-button{
    height: 50px;
    width: 100%;
    background: url("../../assets/images/socials/telegram_support.svg");
    background-repeat: no-repeat;
  }

  .whatsapp-button{
    height: 50px;
    width: 100%;
    background: url("../../assets/images/socials/whatsapp.svg");
    background-repeat: no-repeat;
  }

  .whatsapp-care-button{
    height: 50px;
    width: 100%;
    background: url("../../assets/images/socials/whatsappSupport.svg");
    background-repeat: no-repeat;
  }
}


.v111_3166 {
  width: 179px;
  height: 48px;
  background: rgba(255,255,255,1);
  padding: 14px 16px;
  margin: 8px;
  opacity: 1;
  //position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 4px 0px rgba(0.11764705926179886, 0.843137264251709, 0.3764705955982208, 1);
  overflow: hidden;
}
.name {
  color: #fff;
}
.v111_3168 {
  width: 119px;
  color: rgba(0,0,0,1);
  top: 14px;
  left: 44px;
  font-family: Helvetica Neue;
  font-weight: Bold;
  font-size: 14px;
  opacity: 1;
  text-align: left;
}

.left-side-menu-item__label{
  text-transform: capitalize;
}