.popularGames-content {
  width: 100%;
  display: grid;
  justify-content: center;
  // grid-template-columns: 100px 100px;
  grid-auto-columns: 145px;
  grid-template-rows: auto auto;
  grid-row-gap: 5px;
  grid-column-gap: 12px;
  margin-top: 12px;
  grid-auto-flow: column;
  overflow-x: scroll;
  justify-content: space-between;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  & .popularGames-item {
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    // width: 100px;
  }
  .popularGames-img-body {
    // width: 100%;
    // height: 125px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    position: relative;
    //background-size: 100% 111%;
    //background-position: 0 14px
    &-image{
      height: auto;
      width: 200px;
      max-width: 100%;
      border-radius: 5px;
      max-height: 164px;
    }
  }
  .popularGames-item-name {
    position: relative;
    font-weight: 500;
    font-size: 9px;
    text-transform: capitalize;
    color: #FFFFFF;
    top: 97px;
    left: 5px;
  }
}

.inplay-item-list-exchange__header {
  min-height: 32px;
  padding: 5px 0;
  margin-top: 8px;
  --background: white !important;
  --color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: var(--secondary);
  
  &_scrollButtons{
    position: absolute;
    right: 0;
    width: 80px;
    height: 30px;
    border-radius: 20px;
    border: 1px grey;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    overflow: hidden;
    &-divider{
      height: 100%;
      width: 1px;
      background-color: white;
    }
    &-left, &-right{
      display: flex;
      justify-content: center;
      align-items: center;
    background-color: var(--black);
      color: var(--white);
      width: 100%;
      height: 100%;
      overflow: hidden;
      &.disabled{
        opacity: 0.3;
      }
    }
  }
}

@media(min-width: 1024px) {
  .popularGames-content {
    grid-template-columns: 130px 130px;
    & .popularGames-item {
      width: 130px;
      height: 170px;
    }
    .popularGames-img-body {
      height: 100%;
    }
    .popularGames-item-name {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      color: #FFFFFF;
      top: 137px;
      left: 5px;
    }
  }
}

