@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;

      // ion-input.error {
      //   border: 1px solid var(--ion-color-danger);
      // }
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--secondary);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      //background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}
.footerBlock{
  height: 57px;
  width: 100%;
  background: var(--primary);
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content{
    color: #fff;
    //margin-left: 43%;
    //padding-top: 25px;
    font-size: 12px;

  }
}
.rulesAndRegulations:hover{
  border-bottom: 0.5px solid #fff;
  cursor: pointer;
}

.copyright::after{
  content: '\00a9'
}

.andSymbol::after{
  content: '\0026';
  display: inline-block;
  padding-right: 2px;
}




.landing-footer {
  /*gap: 2rem;*/
  gap: unset;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.tutorials{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .tutorial-btn{
    background-color: var(--cta-primary);
    padding: 10px 16px;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    /* font-weight: bold; */
    cursor: pointer;
    margin: 15px 0px 0px 12px;
    a{
      text-decoration: none;
      color: inherit;
    }
  }
}
.landing-footer-social-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 5px;
  //transition: all 0.4s;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
}
.landing-footer-social-button span {
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--white);
  opacity: 0;
}
.landing-footer-social-button.instagram {
  background-image: url("../../assets/images/footer/social/instagramGray.svg");
}
.landing-footer-social-button.youtube {
  background-image: url("../../assets/images/footer/social/youtube.svg");
}
.landing-footer-social-button.instagram:hover {
  //background-image: url("../../assets/images/footer/social/instagramBlue.svg");
}
.landing-footer-social-button.instagram:hover span {
  opacity: 1;
}
.landing-footer-social-button.fb {
  background-image: url("../../assets/images/footer/social/fbGray.svg");
}
.landing-footer-social-button.fb:hover {
  //background-image: url("../../assets/images/footer/social/fbBlue.svg");
}
.landing-footer-social-button.fb:hover span {
  opacity: 1;
}
.landing-footer-social-button.telegram {
  background-image: url("../../assets/images/footer/social/telegramGray.svg");
}
.landing-footer-social-button.telegram:hover {
  //background-image: url("../../assets/images/footer/social/telegramBlue.svg");
}
.landing-footer-social-button.telegram:hover span {
  opacity: 1;
}
.landing-footer-social-button.whatsapp {
  background-image: url("../../assets/images/footer/social/whatsappGray.svg");
}
.landing-footer-social-button.android {
  background-image: url("../../assets/images/footer/downloadAndroid.svg");
}

.landing-footer-social-button.whatsapp:hover {
  //background-image: url("../../assets/images/footer/social/whatsappBlue.svg");
}
.landing-footer-social-button.whatsapp:hover span {
  opacity: 1;
}
.landing-footer-social-button.mail {
  background-image: url("../../assets/images/footer/social/mailGray.svg");
}
.landing-footer-social-button.mail:hover {
  //background-image: url("../../assets/images/footer/social/mailBlue.svg");
}
.landing-footer-social-button.mail:hover span {
  opacity: 1;
}

.landing-footer-desktop {
  width: 100%;
  /*height: 500px;*/
  /*background: var(--footerBG);*/
  background: var(--black);
}
.landing-footer-desktop__number{
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.android-button {
  height: 50px;
  width: 135px;
  background: url("../../assets/images/footer/downloadAndroid.svg");
  background-repeat: no-repeat;
}

.landing-footer-desktop__companies {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.landing-footer-desktop__companies-img {
  height: 142px;
  width: 15.5%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.landing-footer-desktop__companies-info {
  color: var(--white);
  width: 72.5%;
  padding-right: 44px;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  font-family: Roboto;
  line-height: 19px;
}
.landing-footer-desktop__companies-logos-rows-wrapper {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 11px;
  padding: 16px 44px 0 73px;
  border-bottom: 1px solid #282828;
}
.landing-footer-desktop__companies-logos-row {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 11px;
}
.landing-footer-desktop__companies-logos-row.last {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 50%;
  margin: 0 auto;
  padding-bottom: 21px;
  /*border-bottom: 1px solid #282828;*/
}
.landing-footer-desktop__companies-logos-row img {
  align-self: center;
  justify-self: center;
}

.landing-footer-desktop__social-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  //justify-content: center;
  /*padding-top: 24px;*/
  height: 135px;
  padding: 24px 0;
  //height: 52px;
  font-size: 13px;
  font-weight: 400;
  font-family: Roboto;
}
//.landing-footer-social {
//  display: none;
//}
.landing-footer-desktop-social-button {
  display: inline-block;
  height: 25px;
  width: 26px;
  margin: 0 10px;
  //transition: all 0.4s;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
}
.landing-footer-desktop__social-faq {
  cursor: pointer;
  color: var(--white);
  padding: 30px 0 11px 0;
}
.landing-footer-desktop__social-copyright {
  color: #7C7C7C;
}

@media (max-width: 850px){
  .landing-footer-desktop__companies {
    padding-top: 15px;
  }
  .landing-footer-desktop__companies-logos-rows-wrapper {
  }
  .landing-footer-desktop__companies-logos-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 11px;
    grid-row-gap: 23px;
  }
  .landing-footer-desktop__companies-logos-row.last {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
}

@media (max-width: 764px){
  .landing-footer-desktop {
    display: flex;
    flex-direction: column;
  }
  .landing-footer-desktop__companies-wrapper {
    order: 2;
  }
  .landing-footer-desktop__social-wrapper {
    display: none;
    order: 1;
    height: 70px;
  }
  .landing-footer-desktop__social-copyright {
    display: none;
  }
  .landing-footer-desktop__social-faq {
    order: 1;
    padding: 67px 0 32px 0;
  }
  .landing-footer-social {
    order: 2;
    display: block;
  }
  .landing-footer-social-button {
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 15px;
    //transition: all 0.4s;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
  }
  .landing-footer-social-button span {
    top: 35px;
    font-size: 8px;
  }
  .landing-footer-desktop__companies {
    flex-direction: column;
  }
  .landing-footer-desktop__companies-img {
    width: 275px;
    height: 100px;
  }
  .landing-footer-desktop__companies-logos-row img {
    align-self: center;
    justify-self: start;
  }
  .landing-footer-desktop__companies-info {
    padding: 17px 0 45px 0;
    width: 90%;
  }
  .landing-footer-desktop__companies-logos-rows-wrapper {
    grid-row-gap: 27px;
    padding: 0 2% 50px 10%;
  }
  .landing-footer-desktop__companies-logos-row {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 27px;
    grid-column-gap: unset;
  }
  .landing-footer-desktop__companies-logos-row.last {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 27px;
    grid-column-gap: unset;
  }
}

